import 'focus-visible/dist/focus-visible.min.js'
import '@google/model-viewer/dist/model-viewer.min'
import 'intersection-observer/intersection-observer'

import WOW from 'wow.js/dist/wow.js'
import { tns } from 'tiny-slider/src/tiny-slider'

function init () {
  const wow = new WOW({
    animateClass: 'animate__animated'
  })

  wow.init();

  [].forEach.call(document.querySelectorAll('.image-slider'), function (el) {
    const imageSlider = tns({
      container: '.image-slider',
      controls: false,
      nav: false,
      items: 1,
      autoplay: false,
      loop: true,
      autoWidth: true,
      speed: 1500,
      mouseDrag: true,
      gutter: 16,
      responsive: {
        0: {
          autoWidth: false,
          edgePadding: 44
        },
        576: {
          center: true,
          mouseDrag: true,
          autoWidth: true,
          edgePadding: 0,
          fixedWidth: 540
        },
        768: {
          center: true,
          mouseDrag: true,
          autoWidth: true,
          edgePadding: 0,
          fixedWidth: 720,
          controls: true,
          controlsText: [
            "<svg class='icon'><use xlink:href='#icon-arrow-left'></use></svg>",
            "<svg class='icon'><use xlink:href='#icon-arrow-right'></use></svg>"
          ]
        },
        992: {
          fixedWidth: 464
        },
        1200: {
          fixedWidth: 554
        },
        1400: {
          fixedWidth: 644
        }
      }
    })
  })

  const heroSlider = tns({
    container: '#customize',
    mode: 'gallery',
    items: 1,
    autoplay: false,
    loop: true,
    speed: 1500,
    controlsContainer: '#customize-controls',
    navContainer: '#customize-thumbnails'
  })

  const heroSliderControls = tns({
    container: '#customize-thumbnails',
    mode: 'carousel',
    nav: false,
    items: 3,
    center: true,
    autoplay: false,
    loop: true,
    autoWidth: false,
    speed: 1500,
    controlsContainer: '#customize-controls'
  })
}

init()
